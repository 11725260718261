<template>
	<div class="container">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>客户账号管理</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row class="catopt">
				<el-col :span="12">
					<el-button type="primary" class="add-test" @click="add">新增单位</el-button>
				</el-col>
			</el-row>
			<el-table :data="companyData" border stripe style="width: 100%">
				<el-table-column prop="company" label="项目">
				</el-table-column>
				<!--                <el-table-column
                    prop="branch_level"
                    label="权限等级">
                </el-table-column> -->
				<el-table-column prop="account" label="账号">
				</el-table-column>
				<el-table-column prop="name" label="姓名">
				</el-table-column>
				<el-table-column prop="status" label="状态">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
							active-value="open" inactive-value="close"
							@change="adminStatus(scope.row.status,scope.row.id)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="登录链接">
					{{loginUrl}}
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="editadmin(scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum"
				:page-sizes="[5,10,15,20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>
		<el-dialog title="添加管理员" :visible.sync="dialogVisible" width="50%">
			<div>
				<el-form ref="form" label-width="120px">
					<el-form-item label="公司">
						<el-input v-model="company"></el-input>
					</el-form-item>
					<el-form-item label="账号">
						<el-input v-model="account"></el-input>
					</el-form-item>
					<el-form-item label="密码">
						<el-input v-model="password"></el-input>
					</el-form-item>
					<el-form-item label="姓名">
						<el-input v-model="name"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="close">取消</el-button>
				<el-button type="primary" @click="addCompany">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="编辑管理员" :visible.sync="editVisible" width="50%">
			<div>

				<el-form ref="form" label-width="120px">
					<el-form-item label="LOGO-可选">
						<el-upload :data="edit" action="http://www.qingxusuanfa.com/admin/editadmin" :multiple="false" :limit="1" :file-list="fileList"
							list-type="picture-card" :auto-upload="false" ref="upload" :on-success="onSuccess" :on-remove="handleRemove" :on-preview="handlePreview" :on-change="onChange">
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="公司">
						<el-input v-model="edit.company"></el-input>
					</el-form-item>
					<el-form-item label="账号">
						<el-input v-model="edit.account"></el-input>
					</el-form-item>
					<el-form-item label="密码">
						<el-input v-model="edit.password"></el-input>
					</el-form-item>
					<el-form-item label="管理员昵称">
						<el-input v-model="edit.name"></el-input>
					</el-form-item>
					<el-form-item label="设备名称">
						<el-input v-model="edit.imeiName"></el-input>
					</el-form-item>
					<el-form-item label="欢迎语">
						<el-input v-model="edit.welcome"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="editVisible = false">取消</el-button>
				<el-button type="primary" @click="editconfig">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import loginUrl from "../utils/baseUrl.js";
	import {
		add_team,
		team_list,
		editadmin,
		team_status
	} from '../api/team'
	export default {
		data() {
			return {
				fileList:[],
				loginUrl,
				companyData: [],
				pagenum: 1,
				pagesize: 10,
				total: 0,
				editVisible: false,
				dialogVisible: false,
				company: '',
				branch_level: 1,
				account: '',
				password: '',
				name: '',
				edit: {}
			};
		},
		computed: {},
		methods: {
			onSuccess(){
				this.editVisible = false;
				this.$message.success('编辑成功！')
				this.account = '';
				this.password = '';
				this.name = '';
				this.companyList()
			},
			onChange(file,filelist){
				this.fileList = filelist;
			},
			handleRemove() {

			},
			handlePreview(){
				
			},
			editadmin(d) {
				this.editVisible = true;
				d.password = "";
				this.edit = d;
				this.fileList = [loginUrl+d.imgUrl]
			},
			editconfig() {
				console.log("11111");
				if(this.fileList.length){
					this.$refs.upload.submit()
					// return false;
				}
				let d = this.edit;
				if (d.company == '') {
					return this.$message.error('请输入公司名称')
				}
				if (d.account == '') {
					return this.$message.error('请输入账号')
				}
				if (d.password == '') {
					return this.$message.error('请输入密码')
				}
				if (d.name == '') {
					return this.$message.error('请输入管理员姓名')
				}
				// this.companyData.forEach(item =>{
				// 	if(item.account == d.account){
				// 		return this.$message.error('当前账号已注册！')
				// 	}
				// })
				editadmin(d).then(res => {
					if (res.status == 200) {
						this.editVisible = false;
						this.$message.success('编辑成功！')
						this.account = '';
						this.password = '';
						this.name = '';
						this.companyList()
					} else {
						this.$message.success('编辑失败！')
					}
				})
			},
			add() {
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false;
				this.account = '';
				this.password = '';
				this.name = '';
			},
			addCompany() {
				if (this.company == '') {
					return this.$message.error('请输入公司名称')
				}
				if (this.account == '') {
					return this.$message.error('请输入账号')
				}
				if (this.password == '') {
					return this.$message.error('请输入密码')
				}
				if (this.name == '') {
					return this.$message.error('请输入管理员姓名')
				}
				add_team(this.company, this.branch_level, this.account, this.password, this.name).then(res => {
					if (res.status == 400) {
						return this.$message.error('此账号已被注册')
					}
					if (res.status == 200) {
						this.$message.success('添加成功')
					}
					this.dialogVisible = false;
					this.account = '';
					this.password = '';
					this.name = '';
					this.companyList()
				})
			},
			companyList() {
				team_list(this.pagenum, this.pagesize).then(res => {
					if (res.status == 200) {
						this.companyData = res.data.companys;
						this.total = res.data.total
					}
				})
			},
			adminStatus(value, id) {
				team_status(value, id).then(res => {
					if (res.status == 200) {
						this.$message.success('设置成功')
					}
				})
			},
			handleSizeChange(newSize) {
				this.pagesize = newSize;
				this.companyList();
			},
			handleCurrentChange(newPage) {
				this.pagenum = newPage;
				this.companyList();
			},
		},
		created() {
			this.companyList()
		},
	};
</script>
<style lang='less' scoped>
	.catopt {
		margin-bottom: 10px;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-around;
	}
</style>